import React, { useEffect, useState, useRef } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/init-firebase';
import './Table.css';

const Table = () => {
  const [data, setData] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);

  const adressListRef = useRef(null);

  useEffect(() => {
    const dataCollectionRef = collection(db, 'tokens');
    const unsubscribe = onSnapshot(dataCollectionRef, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
      setData(newData);

      // Отримайте індекси нових рядків, що були додані
      const newRowsIndexes = newData
        .filter((newRow) => !data.some((row) => row.id === newRow.id))
        .map((newRow) => data.findIndex((row) => row.id === newRow.id));

      // Позначте нові рядки як виділені
      setHighlightedRows(newRowsIndexes);

      // Зняти позначку з виділених рядків після 2 секунд
      setTimeout(() => {
        setHighlightedRows([]);
      }, 2000);
    });

    return () => {
      unsubscribe();
    };
  }, [data]);


useEffect(() => {
  if (adressListRef.current) {
    adressListRef.current.scrollTop = adressListRef.current.scrollHeight;
  }
}, [data]);

const timestampToReadableDate = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
  return formattedDate;
};

const truncateEthereumAddress = (address, length = 5) => {
  if (!address || address.length <= length) {
    return address;
  }
  const prefix = address.slice(0, length);
  const suffix = address.slice(-length);
  return `${prefix}...${suffix}`;
};

const handlerClick = (address) => {
  window.open(`https://etherscan.io/address/${address}`, '_blank');
};


return (
  <div className="table-container">
    <div className="custom-table">
      <div className="table-header">
        <div className="table-cell">Time</div>
        <div className="table-cell">Address</div>
        <div className="table-cell">Chain</div>
      </div>
      <div className="table-body" ref={adressListRef}>
        {data.map((token, index) => (
          <div
            className={`table-row ${highlightedRows.includes(index) ? 'green-bg' : ''}`}
            key={index}
            onClick={() => handlerClick(token.data.address)}
          >
            <div className="table-cell">{timestampToReadableDate(token.id * 1)}</div>
            <div className="table-cell">{truncateEthereumAddress(token.data.address)}</div>
            <div className="table-cell">{token.data.chain}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
};


export default Table;
