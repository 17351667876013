import React, { useState, useEffect } from 'react';
import LogoIcon from '../../images/logo.png';
import { Link, useLocation } from 'react-router-dom';

import './Header.css';


function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Функция, которая определяет, является ли ссылка активной
  const isActiveLink = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <header className={isScrolled ? 'header scrolled' : 'header'}>
      <div className="logo">
        <Link to='/'>
         <img src={LogoIcon} alt="Logo" />
        </Link>
      </div>
      <div className="button">
        <Link to="/" className={isActiveLink('/') + ' glitch'}>
          Home
        </Link>
        <Link  to="/community" className={isActiveLink('#') + ' glitch'}>
          Community
        </Link>
        <Link
          to="/recently-deployed-tokens"
          className={isActiveLink('/recently-deployed-tokens') + ' glitch'}
        >
          Recently_deployed_tokens
        </Link>
      </div>
    </header>
  );
}

export default Header;
