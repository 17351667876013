import Typed from "typed.js";
import { useEffect, useRef } from "react";

import '../../App.css';


function Home() {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Find Your Coin", "Find Your Shitcoin"],
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 1000,
      smartBackspace: true,
      // loop: true,
      showCursor: true,
      cursorChar: " _"
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);



  return (
    <div className="home-page">
    <div className="main-logo">
        <h1 className="text-3d">FYS</h1>
        <span ref={el} className="sub-heading"></span>
      </div>
      <div className='position-absolute bg-circle hero-v1'></div>
    </div>
  );
}

export default Home;
