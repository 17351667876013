import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDJxrvWGCadOC0GU2ShaI7BMVKv2CEZSbU",
  authDomain: "test-d5d00.firebaseapp.com",
  databaseURL: "https://test-d5d00-default-rtdb.firebaseio.com",
  projectId: "test-d5d00",
  storageBucket: "test-d5d00.appspot.com",
  messagingSenderId: "854585653942",
  appId: "1:854585653942:web:090650b3330df74cc04417",
  measurementId: "G-TYBFV6QEZE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)